/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "add-to-calendar-button/assets/css/atcb.css";
import dayjs from "dayjs";
import { maxBy, mean, minBy } from "lodash";
import { rgba } from "polished";
import { Fragment, PropsWithChildren, useMemo } from "react";
import Asset from "src/components/Asset";
import Content from "src/components/Content";
import Icon from "src/components/Icon";
import Intersperse from "src/components/Intersperse";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import ReadMore, { ReadMoreButton } from "src/components/ReadMore";
import Spacer from "src/components/Spacer";
import Timeline2 from "src/components/Timeline2";
import TimelineItem from "src/components/Timeline2/TimelineItem";
import TimelinePeriod from "src/components/Timeline2/TimelinePeriod";
import { Unit, UnitBoudaries } from "src/components/Timeline2/Units";
import Typo from "src/components/Typo";
import Assets from "src/utilities/Assets";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Backgrounded from "src/utilities/components/Backgrounded";
import DelayedView from "src/utilities/components/DelayedView";
import ListPlaceholder, {
  EmptyView,
} from "src/utilities/components/ListPlaceholder";
import useBooleanState from "src/utilities/useBooleanState";
import useResponsive from "src/utilities/useResponsive";
import { RichContent } from "./Actualite";
import { Attachments } from "./DonneesDeMarche";

export default function Evenements() {
  return (
    <Page restricted>
      <EvenementsView />
    </Page>
  );
}

function EvenementsView() {
  const { repository } = Services.use();

  const actions = repository.useData((r) => r.getActions());
  const showGantt = useResponsive({ 700: false, bigger: true });

  const containerCss = css({
    background: "white",
    borderRadius: 20,
    paddingBlock: 20,
    paddingInline: 60,
  });

  return (
    <Fragment>
      <Intro title="Calendrier" intro="Intranet" />
      <Content>
        <Spacer scale={2} />
        {showGantt ? (
          <div css={containerCss}>
            <DelayedView promise={actions}>
              {(actions) => {
                return (
                  <ListPlaceholder
                    list={actions}
                    ifEmpty={
                      <EmptyView message="Cette section est vide pour le moment. Revenez plus tard" />
                    }
                  >
                    <ProTimeline actions={actions} />
                  </ListPlaceholder>
                );
              }}
            </DelayedView>
          </div>
        ) : null}
        <Spacer scale={2} />
        <DelayedView promise={actions}>
          {(actions) => (
            <Intersperse between={() => <Spacer />}>
              {actions.map((a) => (
                <ActionBlock action={a} key={a.id} />
              ))}
            </Intersperse>
          )}
        </DelayedView>
        <Spacer scale={3} />
      </Content>
    </Fragment>
  );
}

type ProTimelineProps = {
  actions: Array<Model.Action>;
};

function ProTimeline(props: ProTimelineProps) {
  const { actions } = props;

  const minDate = minBy(
    actions.map((a) => dayjs(a.attributes.start).startOf("year")),
    (d) => d.valueOf()
  ) as dayjs.Dayjs;

  const maxDate = maxBy(
    actions.map((a) => dayjs(a.attributes.end).endOf("year")),
    (d) => d.valueOf()
  ) as dayjs.Dayjs;

  const containerCss = css({
    paddingBlock: 20,
  });

  const units = useMemo<Array<Unit>>(() => {
    return [
      {
        ...UnitBoudaries.year,
        renderLegend: (d, i) => (
          <Legend index={i} text={dayjs(d).format("YYYY")} />
        ),
        renderBackground: (d, i) => <Background index={i} />,
      },
      {
        ...UnitBoudaries.quarter,
        renderLegend: (d, i) => {
          const q = dayjs(d).quarter();
          return (
            <Legend index={i} text={q === 1 ? `1er trim.` : `${q}e trim.`} />
          );
        },
        renderBackground: (d, i) => <Background index={i} />,
      },
      {
        ...UnitBoudaries.month,
        renderLegend: (d, i) => (
          <Legend index={i} text={dayjs(d).format("MMM")} />
        ),
        renderBackground: (d, i) => <Background index={i} />,
      },
    ];
  }, []);

  return (
    <div css={containerCss}>
      <Timeline2
        from={minDate.toISOString()}
        to={maxDate.toISOString()}
        units={units}
      >
        {actions.map((a) => (
          <ActionLine key={a.id} action={a} />
        ))}
      </Timeline2>
    </div>
  );
}

function textBorder(size: number, color: string) {
  return css({
    textShadow: [
      `${size * -1}px ${size * -1}px 0px ${color}`,
      `0px ${size * -1}px 0px ${color}`,
      `${size}px ${size * -1}px 0px ${color}`,
      `${size * -1}px 0px 0px ${color}`,
      `${size}px 0px 0px ${color}`,
      `${size * -1}px ${size}px 0px ${color}`,
      `0px ${size}px 0px ${color}`,
      `${size}px ${size}px 0px ${color}`,
    ].join(", "),
  });
}

type LegendProps = PropsWithChildren<{
  index: number;
  text: string;
}>;

function Legend(props: LegendProps) {
  const { index, text } = props;

  if (index === 0) {
    const style = css({
      borderLeft: `1px solid black`,
      padding: 10,
      paddingTop: 0,
    });
    return (
      <div css={style}>
        <Typo typo="menuItem">{text}</Typo>
      </div>
    );
  } else {
    const style = css({
      borderLeft: `1px solid ${rgba("black", 1)}`,
      borderBottom: `1px solid ${rgba("black", 1)}`,
      padding: 10,
      paddingTop: 0,
    });

    return (
      <div css={style}>
        <Typo typo="minor" color={rgba("black", 0.5)}>
          {text}
        </Typo>
      </div>
    );
  }
}

type BackgroundProps = PropsWithChildren<{
  index: number;
}>;

function Background(props: BackgroundProps) {
  const { index } = props;

  if (index === 0) {
    const style = css({
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderLeft: `1px solid black`,
    });
    return <div css={style}></div>;
  } else {
    const style = css({
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderLeft: `1px dashed ${rgba("black", 0.1)}`,
    });

    return <div css={style}></div>;
  }
}

type ActionLineProps = {
  action: Model.Action;
};

function ActionLine(props: ActionLineProps) {
  const { action } = props;

  const containerCss = css({
    paddingBlock: 20,
  });

  const actionNameCss = css(
    {
      paddingBottom: 2,
      color: Theme.colors.yellow,
    },
    textBorder(2, "white")
  );

  const actionLineCss = css({
    height: 8,
    borderRadius: 4,
    background: Theme.colors.yellow,
    paddingBlock: 2,
    marginBlock: 2,
    outline: `2px solid white`,
    position: "relative",
  });

  const progressionLineCss = css({
    height: 4,
    background: "white",
    borderRadius: 4,
    marginInline: 2,
  });

  const eventCss = css({
    display: "flex",
    paddingBlock: 5,
  });

  const eventDotCss = css({
    width: 14,
    height: 14,
    marginRight: 10,
    background: Theme.colors.green5,
    borderRadius: 10,
  });

  const progression = useMemo(() => {
    return mean(
      action.attributes.etapes.data.map((e) => e.attributes.progression)
    );
  }, []);

  return (
    <div css={containerCss}>
      <TimelinePeriod
        key={action.id}
        start={action.attributes.start}
        end={action.attributes.end}
      >
        <Typo typo="subbodyBold" css={actionNameCss}>
          {action.attributes.label}
        </Typo>
        <div css={actionLineCss}>
          <div
            css={css(
              progressionLineCss,
              css({ width: `calc(${progression}% - 4px)` })
            )}
          ></div>
        </div>
      </TimelinePeriod>
      {action.attributes.evenements.data.map((e) => {
        return (
          <TimelineItem key={e.id} at={e.attributes.date}>
            <div css={eventCss}>
              <div key={e.id} css={eventDotCss}></div>
              <Typo typo="minor" color={Theme.colors.green5}>
                {e.attributes.label}
              </Typo>
            </div>
          </TimelineItem>
        );
      })}
    </div>
  );
}

type ActionBlockProps = {
  action: Model.Action;
};

function ActionBlock(props: ActionBlockProps) {
  const { action } = props;
  const { label, description, start, end } = action.attributes;
  const etapes = action.attributes.etapes.data;

  const stackDisplay = useResponsive({ 700: true, bigger: false });

  const progression = useMemo(() => {
    if (action.attributes.etapes.data.length === 0) return null;
    return mean(
      action.attributes.etapes.data.map((e) => e.attributes.progression)
    );
  }, []);

  const containerCss = css({
    display: "flex",
    flexDirection: stackDisplay ? "column" : "row",
    background: "white",
    borderRadius: 20,
  });

  const leftCss = css({
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: stackDisplay ? 0 : 20,
    borderTopRightRadius: stackDisplay ? 20 : 0,
    background: Theme.colors.green5,
    color: "white",
    width: stackDisplay ? "100%" : "40%",
    flexShrink: 0,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
  });
  const infosCss = css({});
  const progressionCss = css({});
  const rightCss = css({
    flex: 1,
    padding: 40,
  });

  const eventCss = css({
    borderLeft: `4px solid ${Theme.colors.yellow}`,
    padding: 10,
  });

  const [more, setMore] = useBooleanState(false);

  const events = action.attributes.evenements.data;

  return (
    <div css={containerCss}>
      <Backgrounded
        backgrounds={[
          <Asset
            asset={Assets.panther}
            css={css({ position: "absolute", top: 0, left: 0 })}
          />,
        ]}
        css={leftCss}
      >
        <div css={infosCss}>
          <Typo typo="section">{label}</Typo>
          <Typo typo="subbody">
            Du {dayjs(start).format("LL")} au {dayjs(end).format("LL")}
          </Typo>
        </div>
        <Spacer />
        {progression !== null ? (
          <div css={progressionCss}>
            <Typo typo="subbody">Avancement</Typo>
            <Typo typo="heading">{Math.round(progression)}%</Typo>
          </div>
        ) : null}
      </Backgrounded>
      <div css={rightCss}>
        <Intersperse between={() => <Spacer />}>
          {description ? (
            <ReadMore
              unfolded={more}
              foldedHeight={50}
              toggler={
                <ReadMoreButton unfolded={more} onToggle={setMore.toggle} />
              }
            >
              <RichContent>{description}</RichContent>
            </ReadMore>
          ) : null}
          <Intersperse between={() => <Spacer />}>
            {etapes.map((etape) => (
              <StepBlock step={etape} key={etape.id} />
            ))}
          </Intersperse>
          <ListPlaceholder list={events} ifEmpty={<Fragment></Fragment>}>
            <Intersperse between={() => <Spacer scale={0.5} />}>
              {events.map((e) => (
                <div css={eventCss}>
                  <Typo typo="subbodyBold">{e.attributes.label}</Typo>
                  <Typo typo="subbody">
                    Le {dayjs(e.attributes.date).format("LL")}
                  </Typo>
                  <Spacer />
                  <Attachments files={e.attributes.files.data} />
                </div>
              ))}
            </Intersperse>
          </ListPlaceholder>
        </Intersperse>
      </div>
    </div>
  );
}

type StepBlockProps = {
  step: Model.Step;
};

function StepBlock(props: StepBlockProps) {
  const { step } = props;

  const [unfolded, setUnfolded] = useBooleanState(false);
  const containerCss = css({
    display: "flex",
  });
  const arrowCss = css({
    flexShrink: 1,
    padding: 10,
    paddingLeft: 0,
  });
  const iconCss = css({
    transform: `rotate(${unfolded ? "90deg" : "0deg"})`,
  });

  const contentCss = css({});

  const presentationCss = css({
    paddingBlock: 10,
    display: "flex",
    alignItems: "center",
  });

  const badgeCss = css({
    color: "white",
    paddingInline: 7,
    paddingBlock: 3,
    borderRadius: 20,
  });

  let progressionText: string;
  let progressionColor: string;
  if (step.attributes.progression === 0) {
    progressionText = "A venir";
    progressionColor = "grey";
  } else if (step.attributes.progression === 100) {
    progressionText = "Terminé";
    progressionColor = Theme.colors.green5;
  } else {
    progressionText = `En cours - ${Math.round(step.attributes.progression)}%`;
    progressionColor = Theme.colors.yellow;
  }

  return (
    <div css={containerCss}>
      <div css={arrowCss} onClick={setUnfolded.toggle}>
        <Icon name="arrow-right" css={iconCss} typo="body" />
      </div>
      <div css={contentCss}>
        <div css={presentationCss} onClick={setUnfolded.toggle}>
          <Typo>{step.attributes.label}</Typo>
          <Spacer />
          <Typo
            typo="subbodyBold"
            css={css(badgeCss, { backgroundColor: progressionColor })}
          >
            {progressionText}
          </Typo>
        </div>
        <ReadMore unfolded={unfolded} foldedHeight={0}>
          <RichContent>{step.attributes.detail}</RichContent>
        </ReadMore>
      </div>
    </div>
  );
}
