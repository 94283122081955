import dayjs from "dayjs";
import { useCallback, useContext, useMemo } from "react";
import TimelineContext from "./TimelineContext";
import { Unit } from "./Units";

type TimelineLegendProps = {
  unit: Unit;
  index: number;
};

export default function TimelineLegend(props: TimelineLegendProps) {
  const { unit, index } = props;

  const { from, to } = useContext(TimelineContext);

  const getStops = useCallback(
    (from: string, to: string, unit: Unit | null) => {
      const stops: Array<string> = [];
      if (!unit) return stops;
      let cursor = dayjs(from);
      while (dayjs(cursor).isSameOrBefore(to)) {
        stops.push(cursor.toISOString());
        cursor = dayjs(unit.getEnd(cursor.toISOString())).add(1, "ms");
      }
      return stops;
    },
    []
  );

  const stops = useMemo(() => getStops(from, to, unit), [from, to, unit]);

  const getWidth = useCallback(
    (date: string, next: string | null) => {
      const end = next || to;
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const duration = dayjs.duration(dayjs(end).diff(date)).asMilliseconds();
      const width = duration / total;
      return width;
    },
    [from, to]
  );

  return (
    <div className="legend">
      {stops.map((s, i) => {
        const width = getWidth(s, stops[i + 1] || null);
        const percentage = `${width * 100}%`;
        return (
          <div className="legend-item" style={{ width: percentage }}>
            {unit.renderLegend(s, index)}
          </div>
        );
      })}
    </div>
  );
}
