import dayjs from "dayjs";
import { ReactNode } from "react";

export type UnitBoundries = {
  getStart: (date: string) => string;
  getEnd: (date: string) => string;
};

export type Unit = UnitBoundries & {
  renderLegend: (date: string, rank: number) => ReactNode;
  renderBackground: (date: string, rank: number) => ReactNode;
};

const yearBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("year").toISOString(),
  getEnd: (d) => dayjs(d).endOf("year").toISOString(),
};

const quarterBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("quarter").toISOString(),
  getEnd: (d) => dayjs(d).endOf("quarter").toISOString(),
};

const monthBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("month").toISOString(),
  getEnd: (d) => dayjs(d).endOf("month").toISOString(),
};

const weekBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("week").toISOString(),
  getEnd: (d) => dayjs(d).endOf("week").toISOString(),
};

const dayBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("day").toISOString(),
  getEnd: (d) => dayjs(d).endOf("day").toISOString(),
};

const hourBoundaries: UnitBoundries = {
  getStart: (d) => dayjs(d).startOf("hour").toISOString(),
  getEnd: (d) => dayjs(d).endOf("hour").toISOString(),
};

export const UnitBoudaries = {
  year: yearBoundaries,
  quarter: quarterBoundaries,
  month: monthBoundaries,
  week: weekBoundaries,
  day: dayBoundaries,
  hour: hourBoundaries,
};
