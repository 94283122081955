/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import Asset from "src/components/Asset";
import Clickable from "src/components/Clickable";
import Content from "src/components/Content";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Assets from "src/utilities/Assets";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones, { ZoneKey } from "src/utilities/Zones";
import MyAccountLink from "./MyAccountLink";

export default function HeaderDesktop() {
  const { authentication } = Services.use();
  const isLoggedIn = authentication.useIsLoggedIn();
  const inProZone = Zones.useIsUnderRoute("Professionnels");
  const showProMenu = isLoggedIn && inProZone;

  const wrapperCss = css({
    backgroundColor: Theme.colors.white,
    boxShadow: "0px 4px 8px rgba(41,41,41, 0.08)",
  });

  const menuCss = css({
    display: "flex",
  });

  const contentCss = css({
    display: "flex",
    height: Theme.sizes.headerHeight,
    paddingLeft: Theme.S * 2,
  });

  const dotCss = css({
    width: 11,
    height: 11,
    borderRadius: 11 / 2,
    backgroundColor: Theme.colors.yellow,
    alignSelf: "center",
  });

  const userZone = css(Css.clickableReset, {
    width: 230,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const proMenuCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.white,
    borderTop: `1px solid ${rgba(Theme.colors.green5, 0.1)}`,
    padding: Theme.S,
  });

  return (
    <div css={wrapperCss}>
      <Content css={contentCss}>
        <Clickable
          to={Zones.getPath("Home")}
          css={css({ alignSelf: "center" })}
        >
          <Asset asset={Assets.logo} />
        </Clickable>
        <Spacer grow />
        <menu css={menuCss}>
          <MenuItem zone={"Articles"} label="Articles" />
          <div css={dotCss} />
          <MenuItem zone={"PointsDeVente"} label="Points de vente" />
          <div css={dotCss} />
          <MenuItem zone={"Integrer"} label="Porteur de projet" />
          <div css={dotCss} />
          <MenuItem zone={"Professionnels"} label="Intranet" />
        </menu>
        {isLoggedIn ? <MyAccountLink /> : null}
      </Content>
      {showProMenu ? (
        <menu css={proMenuCss}>
          <MenuItem zone={"Evenements"} label="Calendrier" />
          <div css={dotCss} />
          <MenuItem
            zone={"BaseDeDonnees"}
            label="Informations sur les plantes"
          />
          <div css={dotCss} />
          <MenuItem zone={"DonneesDeMarche"} label="Centre de ressources" />
          <div css={dotCss} />
          <MenuItem zone={"Echanges"} label="Echanges" />
        </menu>
      ) : null}
    </div>
  );
}

type MenuItemProps = {
  zone: ZoneKey;
  label: string;
};

function MenuItem(props: MenuItemProps) {
  const { zone, label } = props;

  const isSelected = Zones.useIsUnderRoute(zone);

  let itemCss = css(Css.clickableReset, {
    display: "flex",
    paddingInline: Theme.S * 2,
    alignItems: "center",
    transition: "all 200ms",
  });

  if (isSelected) {
    itemCss = css(itemCss, {
      color: Theme.colors.yellow,
    });
  } else {
    itemCss = css(itemCss, {
      "&:hover": {
        color: Theme.colors.yellow,
      },
    });
  }

  return (
    <Clickable css={itemCss} to={Zones.getPath(zone)}>
      <Typo typo="menuItem" oneLine>
        {label}
      </Typo>
    </Clickable>
  );
}
