/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, PropsWithChildren, ReactNode } from "react";
import PropsWithCss from "src/utilities/PropsWithCss";
import Theme from "src/utilities/Theme";
import useResponsive from "src/utilities/useResponsive";
import useSubmit from "src/utilities/useSubmit";
import Button from "../Button";
import Icon from "../Icon";
import Spacer from "../Spacer";
import Typo from "../Typo";

type FormProps = PropsWithCss<
  PropsWithChildren<{
    icon: string;
    title: string;
    onSubmit: () => any;
    submitLabel?: string;
    after?: ReactNode;
  }>
>;

export default function Form(props: FormProps) {
  const {
    icon,
    title,
    onSubmit,
    submitLabel = "Valider",
    children,
    after,
  } = props;

  const smallScreen = useResponsive({ 500: true, bigger: false });

  const [onFormSubmit, submiting] = useSubmit(
    async (e: React.FormEvent<HTMLFormElement>) => {
      console.log("SUBMIT");
      e.preventDefault();
      e.stopPropagation();
      await onSubmit();
    },
    [onSubmit]
  );

  const formCss = css({
    background: Theme.colors.white,
    borderRadius: 20,
    paddingInline: Theme.S * (smallScreen ? 2 : 4),
    paddingBlock: Theme.S * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  return (
    <form onSubmit={onFormSubmit} css={formCss} className={props.className}>
      <Icon name={icon} size={32} />
      <Typo center typo="subheading">
        {title}
      </Typo>
      <Spacer scale={2} />
      <div css={css({ width: "100%" })}>{children}</div>
      <Spacer scale={2} />
      <Button
        label={submitLabel}
        submit
        loading={submiting}
        disabled={submiting}
      />
      {after ? (
        <Fragment>
          <Spacer scale={2} />
          {after}
        </Fragment>
      ) : null}
    </form>
  );
}
