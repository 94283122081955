import dayjs from "dayjs";
import { PropsWithChildren, useContext, useMemo } from "react";
import TimelineContext from "./TimelineContext";
import { percent } from "./utils";

type TimelinePeriodProps = PropsWithChildren<{
  start: string;
  end: string;
}>;

export default function TimelinePeriod(props: TimelinePeriodProps) {
  const { start, end, children } = props;
  const { from, to } = useContext(TimelineContext);

  const paddingLeft = useMemo(() => {
    const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
    const before = dayjs.duration(dayjs(start).diff(from)).asMilliseconds();
    const paddingLeft = before / total;
    return percent(paddingLeft);
  }, [from, to, start]);

  const paddingRight = useMemo(() => {
    const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
    const after = dayjs.duration(dayjs(to).diff(end)).asMilliseconds();
    const paddingRight = after / total;
    return percent(paddingRight);
  }, [from, to, start]);

  return <div style={{ paddingLeft, paddingRight }}>{children}</div>;
}
