/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { darken, readableColor, rgba } from "polished";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import Intersperse from "src/components/Intersperse";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import AutoHeightInput from "src/utilities/AutoHeightInput";
import Css from "src/utilities/Css";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Strapi from "src/utilities/Strapi";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import Zones from "src/utilities/Zones";
import DelayedView from "src/utilities/components/DelayedView";
import { globalEchangesUpdater } from "src/utilities/globalEchangesUpdater";
import useBooleanState from "src/utilities/useBooleanState";
import stc from "string-to-color";
import { useForumController } from "./Echanges/ForumController";

type SentMessage = {
  id: number | null;
  localId: string;
  contenu: string;
  createdAt: string | null;
};

export default function Sujet() {
  const topic = Zones.useParam("sujet");
  const { repository } = Services.use();
  const controller = useForumController();
  const me = controller.useMe();
  const sujet = controller.useTopic(parseInt(topic));

  const messages = repository.useData(
    () => repository.getMessages(topic),
    [topic]
  );

  const wrapperCss = css({
    flexGrow: 1,
    position: "relative",
  });

  const containerCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    background: rgba("white", 0.7),
    backdropFilter: "blur(10px)",
    borderRadius: 10,
  });

  return (
    <div css={wrapperCss} key={topic}>
      <div css={containerCss}>
        <DelayedView promise={messages} grow>
          {(messages) => (
            <SubjectView sujet={sujet} messages={messages} me={me} />
          )}
        </DelayedView>
      </div>
    </div>
  );
}

type SubjectViewProps = {
  sujet: Model.Topic;
  messages: Strapi.ListResponse<Model.Message>;
  me: Model.Me;
};

function SubjectView(props: SubjectViewProps) {
  const { repository } = Services.use();
  const controller = useForumController();
  const { messages, sujet, me } = props;
  const topicId = parseInt(sujet.id.toString());

  const [sentMessages, setSentMessages] = useState<Array<SentMessage>>([]);

  const displayedMessageCount = messages.data.length + sentMessages.length;

  const headerCss = css({
    padding: 20,
    alignItems: "center",
    display: "flex",
    borderBottom: `1px solid ${Theme.colors.green4}`,
  });

  const messagesScrollerCss = css({
    flexShrink: 1,
    flexGrow: 1,
    minHeight: 0,
    overflow: "auto",
  });

  const messagesCss = css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: 10,
  });

  const [message, setMessage] = useState<string>("");

  const [scrollAtBottom, setScrollAtBottom] = useBooleanState(true);
  const [alreadyScrolled, setAlreadyScrolled] = useBooleanState(false);

  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = scrollerRef.current;
    if (!el) return;
    const onWheel = () => {
      const scroll = el.scrollTop;
      const maxScroll = el.scrollHeight - el.clientHeight;
      setAlreadyScrolled.toTrue();
      setScrollAtBottom.to(scroll === maxScroll);
    };
    el.addEventListener("wheel", onWheel);
    return () => el.removeEventListener("wheel", onWheel);
  }, []);

  useEffect(() => {
    const el = scrollerRef.current;
    if (!el) return;
    if (!scrollAtBottom) return;
    const maxScroll = el.scrollHeight - el.clientHeight;
    el.scrollBy({
      top: maxScroll,
      behavior: alreadyScrolled ? "smooth" : "auto",
    });
  }, [displayedMessageCount, scrollAtBottom, alreadyScrolled]);

  const typeZoneCss = css({
    display: "flex",
    flexDirection: "row",
    padding: 10,
  });

  const inputCss = css(Css.inputReset, Typos.body, {
    background: "white",
    padding: 10,
    borderRadius: 10,
    border: `1px solid ${Theme.colors.green5}`,
    color: Theme.colors.green5,
  });

  const sendCss = css(Css.clickableReset, {
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: 10,
    borderRadius: 10,
    border: `1px solid ${Theme.colors.green5}`,
    color: Theme.colors.green5,
  });

  const sendMessage = useCallback(
    async (message: string) => {
      const localId = `message${Date.now()}`;
      const sentMessage: SentMessage = {
        id: null,
        localId,
        contenu: message,
        createdAt: null,
      };
      setMessage("");
      setSentMessages((l) => [...l, sentMessage]);
      const newMessage = await controller.createMessage(topicId, message);
      setSentMessages((l) =>
        l.map((m) =>
          m.localId === localId
            ? {
                ...m,
                id: newMessage.data.id,
                createdAt: newMessage.data.attributes.createdAt,
              }
            : m
        )
      );
      globalEchangesUpdater.update();
    },
    [topicId]
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (!scrollAtBottom) return;
      if (!message) return;
      sendMessage(message);
    },
    [sujet, message, me, scrollAtBottom, sendMessage]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== "Enter") return;
      if (e.shiftKey || e.ctrlKey || e.altKey || e.metaKey) {
      } else {
        sendMessage((e.target as HTMLTextAreaElement).value);
        e.preventDefault();
      }
    },
    [sendMessage]
  );

  return (
    <Fragment>
      <div css={headerCss}>
        <Typo typo="subheading">{sujet.attributes.titre}</Typo>
        <Spacer grow />
        <Clickable to={Zones.getPath("Echanges")}>
          <Icon name="close" size={32} />
        </Clickable>
      </div>

      <div css={messagesScrollerCss} ref={scrollerRef}>
        <div css={messagesCss}>
          <Intersperse between={() => <Spacer />}>
            {[
              ...messages.data.map((message) =>
                message.attributes.auteur.data.id === me.id ? (
                  <MyMessage
                    message={message.attributes.contenu}
                    date={message.attributes.createdAt}
                  />
                ) : (
                  <SomeoneElseMessage
                    userId={message.attributes.auteur.data.id}
                    message={message.attributes.contenu}
                    name={`${message.attributes.auteur.data.attributes.firstname} ${message.attributes.auteur.data.attributes.lastname}`}
                    date={message.attributes.createdAt}
                  />
                )
              ),
              ...sentMessages.map((m) => (
                <MyMessage message={m.contenu} date={m.createdAt} />
              )),
            ]}
          </Intersperse>
        </div>
      </div>
      <form css={typeZoneCss} onSubmit={onSubmit}>
        <AutoHeightInput
          css={inputCss}
          value={message}
          onChange={(e) => setMessage((e.target as HTMLTextAreaElement).value)}
          placeholder="Saisissez le message que vous souhaitez en partager dans cette conversation"
          onFocus={setScrollAtBottom.toTrue}
          onKeyDown={onKeyDown}
        />
        <Spacer scale={0.5} />
        <Clickable submit css={sendCss}>
          <Icon name="send" size={32} />
        </Clickable>
      </form>
    </Fragment>
  );
}

type SomeoneElseMessageProps = {
  userId: number;
  message: string;
  name: string;
  date: string;
};

function SomeoneElseMessage(props: SomeoneElseMessageProps) {
  const { message, name, date, userId } = props;

  const colors = useMemo(() => {
    const back = darken(0.3, stc(userId + 14));
    const text = readableColor(back);
    return { back, text };
  }, []);

  const containerCss = css({
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  });

  const bubbleCss = css({
    backgroundColor: colors.back,
    alignSelf: "flex-start",
    padding: 10,
    borderRadius: 10,
    borderBottomLeftRadius: 5,
    color: colors.text,
    whiteSpace: "pre-wrap",
  });

  return (
    <div css={containerCss}>
      <Typo typo="minor">{`${name}, le ${dayjs(date).format(
        "D MMMM YYYY à HH[h]mm"
      )}`}</Typo>
      <div css={bubbleCss}>
        <Typo>{message}</Typo>
      </div>
    </div>
  );
}

type MyMessageProps = {
  message: string;
  date: string | null;
};

function MyMessage(props: MyMessageProps) {
  const { message, date } = props;

  const containerCss = css({
    alignSelf: "flex-end",
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  });

  const bubbleCss = css({
    backgroundColor: "white",
    alignSelf: "flex-end",
    padding: 10,
    borderRadius: 10,
    borderBottomRightRadius: 5,
    opacity: date ? 1 : 0.5,
    whiteSpace: "pre-wrap",
  });

  return (
    <div css={containerCss}>
      <Typo typo="minor">
        {date
          ? `le ${dayjs(date).format("D MMMM YYYY à HH[h]mm")}`
          : "Envoi en cours..."}
      </Typo>
      <div css={bubbleCss}>
        <Typo>{message}</Typo>
      </div>
    </div>
  );
}
