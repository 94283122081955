import dayjs from "dayjs";
import { PropsWithChildren, useContext, useMemo } from "react";
import TimelineContext from "./TimelineContext";
import { percent } from "./utils";

type TimelineItemProps = PropsWithChildren<{
  at: string;
}>;

export default function TimelineItem(props: TimelineItemProps) {
  const { at, children } = props;
  const { from, to } = useContext(TimelineContext);

  const paddingLeft = useMemo(() => {
    const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
    const before = dayjs.duration(dayjs(at).diff(from)).asMilliseconds();
    const paddingLeft = before / total;
    return percent(paddingLeft);
  }, [from, to, at]);

  return (
    <div style={{ paddingLeft }}>
      <div>{children}</div>
    </div>
  );
}
