/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useMemo, useState } from "react";
import Clickable from "src/components/Clickable";
import Content from "src/components/Content";
import Form from "src/components/fields/Form";
import TextField from "src/components/fields/TextField";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import useSubmit from "src/utilities/useSubmit";
import Zones from "src/utilities/Zones";

export default function BaseDeDonnees() {
  const { repository } = Services.use();

  const [nom, setNom] = useState<string | null>(null);
  const [nomScientifique, setNomScientifique] = useState<string | null>(null);
  const [application, setApplication] = useState<string | null>(null);

  const [search, setSearch] = useState<{
    nom: string | null;
    nomScientifique: string | null;
    application: string | null;
  } | null>(null);

  const [onSearch] = useSubmit(() => {
    setSearch({ nom, nomScientifique, application });
  }, [nom, nomScientifique, application]);

  const strictSearchResults = useMemo(() => {
    if (search === null) return null;
    return repository.getPlants({ ...search, large: false });
  }, [search]);

  const largeSearchResults = useMemo(async () => {
    if (search === null) return null;
    if (strictSearchResults === null) return null;
    const results = await strictSearchResults;
    if (results.length > 0) return null;
    else return repository.getPlants({ ...search, large: true });
  }, [strictSearchResults]);
  return (
    <Page restricted>
      <Intro title="Informations sur les plantes" intro="Intranet" />
      <Content>
        <Spacer scale={2} />
        <Form onSubmit={onSearch} title="Rechercher une plante" icon="search">
          <TextField label="Nom vernaculaire" value={nom} onChange={setNom} />
          <Spacer />
          <TextField
            label="Nom scientifique"
            value={nomScientifique}
            onChange={setNomScientifique}
          />
          <Spacer />
          <TextField
            label="Application d'usage"
            value={application}
            onChange={setApplication}
          />
          <Spacer scale={2} />
          {strictSearchResults ? (
            <DelayedView promise={strictSearchResults}>
              {(plantes) => {
                return (
                  <Fragment>
                    <Typo>Résultats de la recherche</Typo>
                    <Results results={plantes} />
                    <DelayedView promise={largeSearchResults}>
                      {(r) => {
                        if (r === null) return;
                        else return <Results results={r} />;
                      }}
                    </DelayedView>
                  </Fragment>
                );
              }}
            </DelayedView>
          ) : (
            <Typo center typo="minor">
              Remplissez au moins l'un des champs suivants pour lancer la
              recherche.
            </Typo>
          )}
        </Form>
        <Spacer scale={2} />
      </Content>
    </Page>
  );
}

type ResultsProps = {
  results: Array<Model.Plant>;
};

function Results(props: ResultsProps) {
  const results = props.results;

  const listCss = css({
    margin: 0,
    padding: 0,
    listStyleType: "none",
  });

  const resultCss = css({
    marginBlock: 5,
    padding: 10,
    background: rgba(Theme.colors.green5, 0.1),
    color: Theme.colors.green5,
    borderRadius: 5,
    display: "block",
  });

  return (
    <ul css={listCss}>
      {results.map((p) => (
        <li key={p.id}>
          <Clickable
            css={resultCss}
            to={Zones.getPath("Plante", {
              params: { plante: p.id.toString() },
            })}
          >
            <Typo>{`${p.attributes.nom} (${p.attributes.nom_scientifique})`}</Typo>
          </Clickable>
        </li>
      ))}
    </ul>
  );
}
