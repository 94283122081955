import dayjs from "dayjs";
import { Fragment, useCallback, useContext, useMemo } from "react";
import TimelineContext from "./TimelineContext";
import { Unit } from "./Units";
import { percent } from "./utils";

type TimelineMarksProps = {
  unit: Unit;
  index: number;
};

export default function TimelineMarks(props: TimelineMarksProps) {
  const { unit, index } = props;

  const { from, to } = useContext(TimelineContext);

  const getStops = useCallback(
    (from: string, to: string, unit: Unit | null) => {
      const stops: Array<string> = [];
      if (!unit) return stops;
      let cursor = dayjs(from);
      while (dayjs(cursor).isSameOrBefore(to)) {
        stops.push(cursor.toISOString());
        cursor = dayjs(unit.getEnd(dayjs(cursor).toISOString())).add(1, "ms");
      }
      return stops;
    },
    []
  );

  const stops = useMemo(() => getStops(from, to, unit), [from, to, unit]);

  const getWidth = useCallback(
    (date: string, next: string | null) => {
      const end = next || to;
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const duration = dayjs.duration(dayjs(end).diff(date)).asMilliseconds();
      const width = duration / total;
      return percent(width);
    },
    [from, to]
  );

  const getOffset = useCallback(
    (date: string) => {
      const total = dayjs.duration(dayjs(to).diff(from)).asMilliseconds();
      const ellapsed = dayjs.duration(dayjs(date).diff(from)).asMilliseconds();
      const offset = ellapsed / total;
      return percent(offset);
    },
    [from, to]
  );

  return (
    <Fragment>
      {stops.map((s, i) => {
        return (
          <div
            className="mark"
            style={{
              width: getWidth(s, stops[i + 1] || null),
              left: getOffset(s),
            }}
          >
            {unit.renderBackground(s, index)}
          </div>
        );
      })}
    </Fragment>
  );
}
