// Timeline

import { Global, css } from "@emotion/react";
import { PropsWithChildren, useMemo } from "react";
import TimelineContext from "./TimelineContext";
import TimelineLegend from "./TimelineLegend";
import TimelineMarks from "./TimelineMarks";
import { Unit } from "./Units";

type TimelineProps = PropsWithChildren<{
  from: string;
  to: string;
  units: Array<Unit>;
  numberOfUnits?: number;
}>;

export default function Timeline(props: TimelineProps) {
  const { from, to, units, numberOfUnits = 2, children } = props;

  const styles = css`
    .timeline {
      .legend {
        display: flex;
      }
      .contents {
        position: relative;
        .background {
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          right: 0px;
          z-index: 1;
          .mark {
            position: absolute;
            top: 0px;
            bottom: 0px;
          }
        }
        .children {
          position: relative;
          z-index: 2;
        }
      }
    }
  `;

  const displayedUnits = useMemo(() => {
    const spreading: Array<number> = [];
    units.forEach((unit, i) => {
      let s = 1;
      const toStart = unit.getStart(to);
      let cursor = unit.getStart(from);
      while (cursor !== toStart) {
        s++;
        cursor = new Date(
          new Date(unit.getEnd(cursor)).valueOf() + 1
        ).toISOString();
      }
      spreading[i] = s;
    });

    const firstUnitIndex = spreading.findIndex((s) => s > 1);
    const firstUnitRealIndex =
      firstUnitIndex === -1 ? units.length - 1 : firstUnitIndex;
    return units.slice(firstUnitRealIndex, numberOfUnits);
  }, [from, to, units]);

  const context = useMemo(() => ({ from, to }), [from, to]);

  return (
    <TimelineContext.Provider value={context}>
      <div className="timeline">
        <Global styles={styles} />
        {displayedUnits.map((unit, i) => (
          <TimelineLegend key={i} unit={unit} index={i} />
        ))}
        <div className="contents">
          <div className="background">
            {displayedUnits.map((unit, i) => (
              <TimelineMarks key={i} unit={unit} index={i} />
            ))}
          </div>
          <div className="children">{children}</div>
        </div>
      </div>
    </TimelineContext.Provider>
  );
}
