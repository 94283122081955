/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Asset from "src/components/Asset";
import Content from "src/components/Content";
import Presentation from "src/components/Presentation";
import SignInForm from "src/components/SignInForm";
import Assets from "src/utilities/Assets";
import Theme from "src/utilities/Theme";
import Backgrounded from "src/utilities/components/Backgrounded";
import useResponsive from "src/utilities/useResponsive";

export default function RestrictedArea() {
  const containerCss = css({
    background: Theme.colors.green5,
    overflow: "hidden",
  });

  const columnsCss = useResponsive({
    800: css({}),
    bigger: css({
      display: "grid",
      gridTemplateColumns: "6fr 4fr",
      gap: Theme.S * 3,
    }),
  });

  const introCss = css({
    position: "relative",
    zIndex: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: Theme.S * 2,
    marginBottom: Theme.S * 2,
  });

  const formeCss = useResponsive({
    800: css({
      zIndex: 1,
      position: "absolute",
      right: "-10%",
      bottom: 0,
    }),
    bigger: css({
      zIndex: 1,
      position: "absolute",
      right: 0,
      bottom: 0,
    }),
  });

  const presCss = css({
    position: "relative",
    zIndex: 2,
    aspectRatio: "1/1",
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "30%",
    justifyContent: "flex-start",
  });

  const formCss = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: Theme.S * 4,
  });

  return (
    <Backgrounded
      css={containerCss}
      backgrounds={[
        <Asset
          asset={Assets.branche}
          css={css({
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: `translate3d(-50%, -50%, 0px)`,
          })}
        />,
        <Asset
          asset={Assets.feuille2}
          css={css({ position: "absolute", right: -30, top: 60 })}
        />,
        <Asset
          asset={Assets.feuille3}
          css={css({ position: "absolute", right: -30, top: 430 })}
        />,
      ]}
    >
      <Content>
        <div css={columnsCss}>
          <div css={introCss}>
            <Asset asset={Assets.forme1} css={formeCss} width="130%" />
            <Presentation
              css={presCss}
              title={"Professionnels"}
              content="Cette section est réservée aux professionnels. Pour y accéder, entrez vos identifiants."
            />
          </div>
          <div css={formCss}>
            <SignInForm />
          </div>
        </div>
      </Content>
    </Backgrounded>
  );

  // return (
  //   <Backgrounded
  //     css={containerCss}
  //     backgrounds={[

  //     ]}
  //   >
  //     <Content>
  //       <div css={columnsCss}>
  //         <div css={introCss}>
  //           <Backgrounded
  //             backgrounds={
  //               <Fragment>

  //               </Fragment>
  //             }
  //           >

  //           </Backgrounded>
  //         </div>
  //         <div css={formCss}>
  //           <SignInForm />
  //         </div>
  //       </div>
  //     </Content>
  //   </Backgrounded>
  // );
}
