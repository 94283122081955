/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmit from "src/utilities/useSubmit";
import Clickable from "./Clickable";
import Spacer from "./Spacer";
import Typo from "./Typo";
import Form from "./fields/Form";
import TextField from "./fields/TextField";

type SignInResult = { jwt: string };

export default function SignInForm() {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [recoverMode, setRecoverMode] = useBooleanState(false);
  const { repository, authentication } = Services.use();

  const formCss = css({
    minWidth: 300,
    width: "70%",
  });

  const onSignIn = useCallback(async () => {
    const result = await repository.api.post<SignInResult>(`/api/auth/local`, {
      identifier: email,
      password: password,
    });
    authentication.setJwt(result.data.jwt);
  }, [email, password]);

  const [onRecover] = useSubmit(async () => {
    await repository.api.post<SignInResult>(`/api/auth/forgot-password`, {
      email: email,
    });
    // authentication.setJwt(result.data.jwt);
  }, [email]);

  if (!recoverMode) {
    return (
      <Form
        icon="user"
        title="Se connecter"
        onSubmit={onSignIn}
        css={formCss}
        after={
          <Clickable onClick={setRecoverMode.toTrue} css={Css.link}>
            <Typo typo="minor">Mot de passe oublié ?</Typo>
          </Clickable>
        }
      >
        <TextField
          value={email}
          onChange={setEmail}
          label="Adresse email"
          type="email"
        />
        <Spacer />
        <TextField
          value={password}
          onChange={setPassword}
          label="Mot de passe"
          type="password"
        />
      </Form>
    );
  } else {
    return (
      <Form
        icon="user"
        title="Mot de passe oublié"
        onSubmit={onRecover}
        css={formCss}
        after={
          <Clickable onClick={setRecoverMode.toFalse} css={Css.link}>
            <Typo typo="minor">Se connecter</Typo>
          </Clickable>
        }
      >
        <TextField
          value={email}
          onChange={setEmail}
          label="Adresse email"
          type="email"
        />
      </Form>
    );
  }
}
