import Routing, { Route, RouteKey } from "./Routing";

const Home: Route = {
  pattern: "/",
  parent: null,
};

const Actualite: Route = {
  pattern: "actualite/:actualite",
  parent: Home,
};

const Articles: Route = {
  pattern: "articles",
  parent: Home,
};

const Article: Route = {
  pattern: "articles/:article",
  parent: Articles,
};

const PointsDeVente: Route = {
  pattern: "points-de-vente",
  parent: Home,
};

const Integrer: Route = {
  pattern: "integrer-la-filiere",
  parent: Home,
};

const Contact: Route = {
  pattern: "contact",
  parent: Home,
};

const Professionnels: Route = {
  pattern: "professionnels",
  parent: Home,
};

const Evenements: Route = {
  pattern: "evenements",
  parent: Professionnels,
};

const Evenement: Route = {
  pattern: ":evenement",
  parent: Evenements,
};

const BaseDeDonnees: Route = {
  pattern: "plantes",
  parent: Professionnels,
};

const Plante: Route = {
  pattern: ":plante",
  parent: BaseDeDonnees,
};

const DonneesDeMarche: Route = {
  pattern: "donnees-de-marche",
  parent: Professionnels,
};

const Echanges: Route = {
  pattern: "echanges",
  parent: Professionnels,
};

const Sujet: Route = {
  pattern: ":sujet",
  parent: Echanges,
};

const NouveauSujet: Route = {
  pattern: "nouveau",
  parent: Echanges,
};

const MotDePasseOublie: Route = {
  pattern: "mot-de-passe-oublie",
  parent: Home,
};

const Espace: Route = {
  pattern: "mon-espace",
  parent: Home,
};

const MentionsLegales: Route = {
  pattern: "/mentions-legales",
  parent: Home,
};

const CharteForum: Route = {
  pattern: "/charte-forum",
  parent: Home,
};

const Confidentialite: Route = {
  pattern: "/politique-de-confidentialite",
  parent: Home,
};

const Zones = new Routing({
  Home,
  Actualite,
  PointsDeVente,
  Articles,
  Article,
  Integrer,
  Contact,
  Professionnels,
  Evenements,
  Evenement,
  BaseDeDonnees,
  DonneesDeMarche,
  Echanges,
  Plante,
  Sujet,
  NouveauSujet,
  Espace,
  MentionsLegales,
  Confidentialite,
  CharteForum,
  MotDePasseOublie,
});

export type ZoneKey = RouteKey<typeof Zones>;

export default Zones;
