/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { trim } from "lodash";
import { rgba } from "polished";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Divider from "src/components/Divider";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import TextField from "src/components/fields/TextField";
import Model from "src/utilities/Model";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import useBooleanState from "src/utilities/useBooleanState";
import useSubmit from "src/utilities/useSubmit";
import { useForumController } from "./Echanges/ForumController";

const formCss = css({
  width: "90%",
  maxWidth: 500,
});

export default function NouveauSujet() {
  const wrapperCss = css({
    flexGrow: 1,
    position: "relative",
  });

  const containerCss = css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    background: rgba("white", 0.7),
    backdropFilter: "blur(10px)",
    borderRadius: 10,
    padding: Theme.S * 2,
    alignItems: "center",
    justifyContent: "center",
  });

  const [result, setResult] = useState<{
    search: string;
    topics: Array<Model.Topic>;
  } | null>(null);

  return (
    <div css={wrapperCss}>
      <div css={containerCss}>
        {result ? <Matches result={result} /> : <Intro onDone={setResult} />}
      </div>
    </div>
  );
}

type IntroProps = {
  onDone: (result: { search: string; topics: Array<Model.Topic> }) => any;
};

function Intro(props: IntroProps) {
  const { onDone } = props;

  const controller = useForumController();

  const [topic, setTopic] = useState<string | null>("");

  const [onSubmit, searching] = useSubmit(
    async (e: React.FormEvent) => {
      if (!topic) return;
      e.preventDefault();
      const results = controller.searchTopics(
        topic.split(" ").map((w) => trim(w))
      );
      onDone({ search: topic, topics: results });
    },
    [topic]
  );

  return (
    <div css={formCss}>
      <Typo typo="subheading">
        Bienvenue sur la zone d'échanges de papampei.fr
      </Typo>
      <Spacer />
      <Typo>
        Vous avez besoin d'informations techniques, proposition de collaboration
        ou besoin d'un service ? Echangez directement entre professionnels dans
        cet espace dédié.
      </Typo>
      <Spacer />
      <Typo>
        Cette section est une agora dont la condition est le respect mutuel.
        Pour en savoir plus, une charte éthique est à votre disposition
      </Typo>
      <Spacer />
      <Button
        submit
        label="Consuler la charte éthique"
        to={Zones.getPath("CharteForum")}
        minor
        color={Theme.colors.green5}
        centered
      />
      <Spacer scale={2} />
      <Divider size={100} />
      <Spacer scale={2} />
      <form onSubmit={onSubmit}>
        <TextField
          value={topic}
          onChange={setTopic}
          label="Quel sujet souhaitez vous aborder ?"
          placeholder=""
        />
        <Spacer />
        <Button submit label="Continuer" centered loading={searching} />
      </form>
    </div>
  );
}

type MatchesProps = {
  result: { search: string; topics: Array<Model.Topic> };
};

function Matches(props: MatchesProps) {
  const { result } = props;

  const [forced, setForced] = useBooleanState(false);

  if (result.topics.length === 0 || forced) {
    return <NoMatches {...props} forced={forced} />;
  } else {
    return <MatchesList {...props} onForce={setForced.toTrue} />;
  }
}

function NoMatches(props: MatchesProps & { forced: boolean }) {
  const { result, forced } = props;
  const controller = useForumController();
  const navigate = useNavigate();

  const [firstMessage, setFirstMessage] = useState<string | null>(null);

  const [onSubmit, creating] = useSubmit(
    async (e: React.FormEvent) => {
      if (!firstMessage) return;
      e.preventDefault();
      const topic = await controller.createTopic(result.search, firstMessage);
      navigate(
        Zones.getPath("Sujet", { params: { sujet: topic.data.id.toString() } })
      );
    },
    [firstMessage]
  );

  return (
    <form css={formCss} onSubmit={onSubmit}>
      <Typo typo="subheading">Parlons "{result.search}" !</Typo>
      {forced ? null : (
        <Typo>
          Personne ne semble avoir abordé ce sujet jusqu'à présent...{" "}
        </Typo>
      )}
      <Spacer />
      <TextField
        value={firstMessage}
        onChange={setFirstMessage}
        textarea
        label="Commencez la discussion avec un premier message"
        placeholder=""
      />
      <Spacer />
      <Button
        submit
        label="Commencer la discussion"
        centered
        loading={creating}
      />
    </form>
  );
}

function MatchesList(props: MatchesProps & { onForce: () => any }) {
  const { result, onForce } = props;

  return (
    <div css={formCss}>
      <Typo typo="subheading">
        Il semblerait qu'on discute déjà "{result.search}"...
      </Typo>
      <Spacer />
      <Typo>
        Voyez si vous pouvez prendre part à l'une de ces discussions :
      </Typo>
      <ul>
        {result.topics.map((t) => (
          <li>
            <Link
              to={Zones.getPath("Sujet", {
                params: { sujet: t.id.toString() },
              })}
            >
              <Typo>{t.attributes.titre}</Typo>
            </Link>
          </li>
        ))}
      </ul>
      <Spacer scale={2} />
      <Divider size={100} />
      <Spacer scale={2} />
      <Typo>Vous préférez entamer une nouvelle discussion ?</Typo>
      <Spacer />
      <Button
        submit
        label="Commencer une nouvelle discussion"
        centered
        onClick={onForce}
      />

      <Spacer />
    </div>
  );
}
