/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { rgba } from "polished";
import { Fragment, useMemo } from "react";
import { Outlet } from "react-router-dom";
import Clickable from "src/components/Clickable";
import Icon from "src/components/Icon";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import Assets, { getBackgroundAsset } from "src/utilities/Assets";
import Css from "src/utilities/Css";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Zones from "src/utilities/Zones";
import Backgrounded from "src/utilities/components/Backgrounded";
import DelayedView from "src/utilities/components/DelayedView";
import NouveauSujet from "../NouveauSujet";
import ForumController, {
  ForumControllerContext,
  useForumController,
} from "./ForumController";

export default function Echanges() {
  const { repository } = Services.use();

  const containerCss = css({
    display: "flex",
    height: "80vh",
    padding: 20,
    overflow: "hidden",
  });

  const montagnesCss = css(getBackgroundAsset(Assets.montagnes), {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: "contain",
    backgroundPosition: "bottom center",
    height: 2000,
    zIndex: 2,
    transform: "scaleX(-1)",
  });

  const controller = useMemo(async () => {
    const controller = new ForumController(repository);
    await controller.init();
    return controller;
  }, []);

  return (
    <Page restricted>
      <DelayedView promise={controller}>
        {(controller) => (
          <ForumControllerContext.Provider value={controller}>
            <Backgrounded
              css={containerCss}
              backgrounds={[<div css={montagnesCss} />]}
            >
              <MainContent />
            </Backgrounded>
          </ForumControllerContext.Provider>
        )}
      </DelayedView>
    </Page>
  );
}

function MainContent() {
  const controller = useForumController();
  const topics = controller.useTopics();

  if (topics.length === 0) return <NouveauSujet />;
  else {
    return (
      <Fragment>
        <Topics />
        <Outlet />
      </Fragment>
    );
  }
}

function Topics() {
  const controller = useForumController();
  const topics = controller.useTopics();

  const containerCss = css({
    background: rgba("white", 0.7),
    backdropFilter: "blur(10px)",
    borderRadius: 10,
    flexShrink: 1,
    width: "35%",
    marginRight: 20,
    display: "flex",
    flexDirection: "column",
  });

  const headerCss = css({
    padding: 20,
    display: "flex",
    alignItems: "center",
  });

  const topicsCss = css({
    borderTop: `1px solid ${Theme.colors.green4}`,
    paddingBottom: 10,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  });

  const plusCss = css(Css.clickableReset, {});

  return (
    <div css={containerCss}>
      <div css={headerCss}>
        <Typo typo="subheading">Sujets de discussion</Typo>
        <Spacer grow />
        <Clickable css={plusCss} to={Zones.getPath("NouveauSujet")}>
          <Icon name="plus" size={40} />
        </Clickable>
      </div>
      <div css={topicsCss}>
        {topics.map((t) => (
          <TopicView topic={t} key={t.id} />
        ))}
      </div>
    </div>
  );
}

type TopicViewProps = {
  topic: Model.Topic;
};
function TopicView(props: TopicViewProps) {
  const { topic } = props;

  const currentTopic = Zones.useParam("sujet", null);
  const selected = currentTopic === topic.id.toString();

  const containerCss = css(Css.clickableReset, {
    padding: 10,
    display: "block",
    borderBottom: `1px solid ${Theme.colors.green5}`,
    background: selected ? rgba(Theme.colors.green5, 0.1) : 0,
    color: selected ? Theme.colors.green5 : "black",
  });

  const firstLineCss = css({
    display: "flex",
  });

  const lastMessageCss = css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  const date =
    topic.attributes.date_dernier_message || topic.attributes.createdAt;

  const lastMessage =
    topic.attributes.dernier_message &&
    topic.attributes.auteur_dernier_message ? (
      <div css={lastMessageCss}>
        <Typo typo="minor" inline>
          {`${topic.attributes.auteur_dernier_message}`}
        </Typo>
        <Typo typo="minor" inline css={css({ opacity: 0.7 })}>
          {` : ${topic.attributes.dernier_message}`}
        </Typo>
      </div>
    ) : null;

  return (
    <Clickable
      css={containerCss}
      to={Zones.getPath("Sujet", { params: { sujet: topic.id.toString() } })}
    >
      <div css={firstLineCss}>
        <Typo typo="account">{topic.attributes.titre}</Typo>
        <Spacer grow />
        <Typo typo="minor" inline css={css({ opacity: 0.7 })}>
          {dayjs(date).fromNow()}
        </Typo>
      </div>

      {lastMessage}
    </Clickable>
  );
}
