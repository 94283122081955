/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, useCallback } from "react";
import Css from "src/utilities/Css";
import Services from "src/utilities/Services";
import Theme from "src/utilities/Theme";
import Typos from "src/utilities/Typos";
import Zones from "src/utilities/Zones";
import DelayedView from "src/utilities/components/DelayedView";
import useResponsive from "src/utilities/useResponsive";
import Clickable from "../Clickable";
import Content from "../Content";
import Divider from "../Divider";
import Spacer from "../Spacer";
import Typo from "../Typo";

export default function FooterLarge() {
  const { repository } = Services.use();

  const mobile = useResponsive({ 600: true, bigger: false });

  const partners = repository.useData((r) => r.getPartners(), []);

  const footerCss = css({
    backgroundColor: Theme.colors.white,
    boxShadow: "0px -4px 8px rgba(41,41,41, 0.08)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  });

  const partsCss = css({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
    justifyContent: "center",
    paddingBlock: Theme.S * 2,
    textAlign: "center",
    alignSelf: mobile ? "center" : "stretch",
  });

  const partCss = css(Typos.subbody, {
    paddingBlock: Theme.S * 2,
    paddingInline: Theme.S * 2,
  });

  const linksCss = css({
    display: "flex",
    flexDirection: "column",
    paddingTop: Theme.S,
  });

  const linkCss = css(Css.clickableReset, {
    paddingBlock: Theme.S / 3,
  });

  const partenairesCss = css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: Theme.S * 3,
  });

  const partnerLinkCss = css({
    padding: 20,
    display: "block",
  });

  const imageCss = css({
    height: 80,
    width: 150,
    objectFit: "contain",
  });

  const { tracking } = Services.use();

  const onClick = useCallback((url: string) => {
    tracking.externalClick(url);
  }, []);

  return (
    <div css={footerCss}>
      <Content css={partsCss}>
        <div css={partCss}>
          <Typo typo="section">Informations</Typo>
          <div css={linksCss}>
            <Clickable css={linkCss} to={Zones.getPath("Articles")}>
              Articles
            </Clickable>
            <Clickable css={linkCss} to={Zones.getPath("PointsDeVente")}>
              Points de vente
            </Clickable>
          </div>
        </div>
        <Divider strenght={1} color={rgba("black", 0.5)} vertical={!mobile} />
        <div css={partCss}>
          <Typo typo="section">Porteur de projet</Typo>
          <div css={linksCss}>
            <Clickable css={linkCss} to={Zones.getPath("Integrer")}>
              Découvrez et rejoignez la filière
            </Clickable>
            <Clickable css={linkCss} to={Zones.getPath("Contact")}>
              Contact
            </Clickable>
          </div>
        </div>
        {/* <Divider strenght={1} color={rgba("black", 0.5)} vertical={!mobile} /> */}
        {/* <div css={partCss}>
          <Typo typo="section">Intranet</Typo>
          <div css={linksCss}>
            <Clickable css={linkCss} to={Zones.getPath("Professionnels")}>
              Se connecter
            </Clickable>
          </div>
        </div> */}
      </Content>
      <Content>
        <DelayedView promise={partners}>
          {(partners) => {
            return (
              <Fragment>
                <div>
                  <Typo center typo="subbody" color={Theme.colors.green5}>
                    La plateforme papampei.fr a été élaborée par Qualitropic
                    avec le soutien financier du Département de la Réunion.
                    <br />
                    La plateforme papampei.fr a été élaborée en partenariat avec
                    les pilotes de la Feuille de Route PAPAM
                  </Typo>
                </div>
                <Spacer />
                <div css={partenairesCss}>
                  {partners.map((p) => {
                    const img = (
                      <img
                        css={imageCss}
                        key={p.id}
                        src={p.attributes.logo.data.attributes.url}
                        alt={p.attributes.label}
                      />
                    );
                    if (p.attributes.link) {
                      return (
                        <a
                          href={p.attributes.link}
                          target="_blank"
                          rel="noreferrer"
                          css={partnerLinkCss}
                          onClick={() => onClick(p.attributes.link as string)}
                          key={p.id}
                        >
                          {img}
                        </a>
                      );
                    } else {
                      return (
                        <div css={partnerLinkCss} key={p.id}>
                          {img}
                        </div>
                      );
                    }
                  })}
                </div>
              </Fragment>
            );
          }}
        </DelayedView>
      </Content>
    </div>
  );
}

/*
   <div css={partenairesCss}>
          
        </div>
*/
