import { sortBy } from "lodash";
import React, { useContext } from "react";
import DataSet from "src/utilities/DataSet";
import Model from "src/utilities/Model";
import Repository from "src/utilities/Repository";
import Updater from "src/utilities/Updater";

export default class ForumController {
  private r = new Updater();
  private topics!: Array<Model.Topic>;
  private me!: Model.Me;

  constructor(private repository: Repository) {}

  async init() {
    this.topics = await this.fetchTopics();
    this.me = await this.repository.getMe();
    this.r.update();
  }

  async fetchTopics() {
    const topics = await this.repository.getTopics();
    return topics.data;
  }

  async createTopic(name: string, message: string) {
    const topic = await this.repository.createTopic(name);
    this.topics = DataSet.upsert(this.topics, topic.data);
    await this.repository.createMessage(topic.data.id, message, this.me.id);
    topic.data.attributes.date_dernier_message = new Date().toISOString();
    topic.data.attributes.auteur_dernier_message = `${this.me.firstname} ${this.me.lastname}`;
    topic.data.attributes.dernier_message = message;
    this.r.update();
    return topic;
  }

  async createMessage(topicId: number, message: string) {
    const newMessage = await this.repository.createMessage(
      topicId,
      message,
      this.me.id
    );
    const topic = DataSet.find(this.topics, topicId);
    topic.attributes.auteur_dernier_message = `${this.me.firstname} ${this.me.lastname}`;
    topic.attributes.date_dernier_message = new Date().toISOString();
    topic.attributes.dernier_message = message;
    this.r.update();
    return newMessage;
  }

  searchTopics(keywords: Array<string>) {
    return this.topics.filter((t) => {
      const topicKeyWords = t.attributes.titre.split(" ");
      const contains = topicKeyWords.find((topicKeyword) => {
        return keywords.find((k) => {
          if (topicKeyword.toUpperCase().indexOf(k.toUpperCase()) >= 0)
            return true;
          else return false;
        });
      });
      return !!contains;
    });
  }

  useMe() {
    return this.r.useValue(() => {
      return this.me;
    });
  }

  useTopics() {
    return this.r.useValue(() => {
      return sortBy(this.topics, (t) =>
        t.attributes.date_dernier_message
          ? new Date(t.attributes.date_dernier_message).valueOf() * -1
          : -1
      );
    }, []);
  }

  useTopic(id: number) {
    return this.r.useValue(() => {
      return DataSet.find(this.topics, id);
    }, [id]);
  }
}

export const ForumControllerContext =
  React.createContext<ForumController | null>(null);
export function useForumController() {
  const ctrl = useContext(ForumControllerContext);
  if (!ctrl) throw new Error("No controller");
  return ctrl;
}
