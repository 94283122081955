/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { filesize } from "filesize";
import { rgba } from "polished";
import { Fragment, useMemo } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Clickable from "src/components/Clickable";
import Divider from "src/components/Divider";
import Icon from "src/components/Icon";
import Intersperse from "src/components/Intersperse";
import Intro from "src/components/Intro";
import Page from "src/components/Page";
import Spacer from "src/components/Spacer";
import Typo from "src/components/Typo";
import DelayedView from "src/utilities/components/DelayedView";
import ListPlaceholder from "src/utilities/components/ListPlaceholder";
import Model from "src/utilities/Model";
import Services from "src/utilities/Services";
import Strapi from "src/utilities/Strapi";
import Theme from "src/utilities/Theme";

export default function DonneesDeMarche() {
  const { repository } = Services.use();

  const ressources = useMemo(() => {
    return repository.getResources();
  }, []);

  const containerCss = css({
    padding: Theme.S * 2,
  });

  return (
    <Page restricted>
      <Intro title="Centre de ressources" height={200} intro="Intranet" />
      <div css={containerCss}>
        <DelayedView promise={ressources}>
          {(ressources) => {
            return (
              <ListPlaceholder list={ressources}>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    500: 1,
                    1000: 2,
                    1400: 3,
                    2000: 4,
                  }}
                >
                  <Masonry columnsCount={3} gutter={Theme.S * 2 + "px"}>
                    {ressources.map((ressource) => {
                      return (
                        <Ressource key={ressource.id} ressource={ressource} />
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </ListPlaceholder>
            );
          }}
        </DelayedView>
      </div>
    </Page>
  );
}

type RessourceProps = {
  ressource: Model.Ressource;
};

function Ressource(props: RessourceProps) {
  const { ressource } = props;

  const containerCss = css({
    background: Theme.colors.white,
    borderRadius: 20,
    padding: Theme.S * 2,
    boxShadow: `0px 4px 8px ${rgba(Theme.colors.black, 0.08)}`,
  });

  const fileCss = css({
    border: `1px solid ${rgba(Theme.colors.green5, 0.3)}`,
    borderRadius: 4,
    display: "flex",
    padding: 4,
    paddingInline: 10,
    backgroundColor: rgba(Theme.colors.green5, 0.1),
    color: Theme.colors.green5,
    transition: "all 200ms",
    "&:hover": {
      border: `1px solid ${rgba(Theme.colors.green5, 1)}`,
    },
  });

  return (
    <div css={containerCss}>
      <Typo typo="intro">{ressource.attributes.libelle}</Typo>
      <Spacer scale={1} />
      <Divider strenght={1} />
      <Spacer scale={1} />
      <Attachments files={ressource.attributes.fichiers.data} />
      {ressource.attributes.description ? (
        <Fragment>
          <Spacer scale={1} />
          <Typo typo="subbodyBold">{ressource.attributes.description}</Typo>
        </Fragment>
      ) : null}
      <Spacer scale={1} />
    </div>
  );
}

type AttachmentsProps = {
  files: Array<Strapi.Model<Strapi.ImageAttributes>>;
};

export function Attachments(props: AttachmentsProps) {
  const { files } = props;

  const listCss = css({
    padding: 0,
    margin: 0,
    listStyleType: "none",
  });

  return (
    <ListPlaceholder list={files} ifEmpty={<Fragment />}>
      <ul css={listCss}>
        <Intersperse between={() => <Spacer scale={0.5} />}>
          {files.map((file) => {
            return <Attachment file={file} key={file.id} />;
          })}
        </Intersperse>
      </ul>
    </ListPlaceholder>
  );
}

type AttachmentProps = {
  file: Strapi.Model<Strapi.ImageAttributes>;
};
export function Attachment(props: AttachmentProps) {
  const { file } = props;
  const category = getFileCategory(file.attributes.mime);
  const extension = getFileExtension(file.attributes.name);
  const size = filesize(file.attributes.size * 1000, {
    locale: "fr",
  });

  const fileCss = css({
    border: `1px solid ${rgba(Theme.colors.green5, 0.3)}`,
    borderRadius: 4,
    display: "flex",
    padding: 4,
    paddingInline: 10,
    backgroundColor: rgba(Theme.colors.green5, 0.1),
    color: Theme.colors.green5,
    transition: "all 200ms",
    "&:hover": {
      border: `1px solid ${rgba(Theme.colors.green5, 1)}`,
    },
  });

  return (
    <li key={file.id}>
      <Clickable css={fileCss} href={file.attributes.url} target="_blank">
        <Icon name="download" size={30} />
        <Spacer scale={0.5} />
        <div>
          <Typo typo="subbodyBold">
            {file.attributes.caption || file.attributes.name}
          </Typo>
          <Typo typo="subbody">
            {`${category}${extension ? " " + extension : ""} (${size})`}
          </Typo>
        </div>
      </Clickable>
    </li>
  );
}

function getFileExtension(filename: string) {
  try {
    const value = filename.split(".").pop();
    if (value) return value.toUpperCase();
    else return null;
  } catch (err) {
    return null;
  }
}

function getFileCategory(mimeType: string) {
  let category = "Fichier";
  if (mimeType.startsWith("image/")) category = "Image";
  else if (mimeType === "application/json") category = "Document";
  return category;
}
